// Cooking hangout

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Amplify from "aws-amplify";
import config from "../aws-exports";
import {
  Banner,
  Image,
  Layout,
  SEO,
  ThreeStepBanner,
  Duo,
} from "../components";
Amplify.configure(config);

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query {
      topDuoImage: file(relativePath: { eq: "chefy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      middleDuoImage: file(relativePath: { eq: "event.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bottomDuoImage: file(relativePath: { eq: "wfh.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <Banner
        title="Build a virtual cooking class today"
        subtitle={
          "We are currently under construction. Join our beta list by signing up and get early access to incredible tools for building cooking experiences online."
        }
      />
      <ThreeStepBanner title="Sign up for beta in a few easy steps" />
      <Duo
        title="Use our tool to easily design your own cooking classes"
        body="Quick and easy-to-use, we walk you through the steps needed to build a successful cooking class."
        reverse={true}
        image={<Image fluid={query.topDuoImage.childImageSharp.fluid} />}
      />
      <Duo
        title="Get your own landing page to easily manage each event"
        body="All of your invitees will receive a link to your landing page and receive automatic email updates if it is changed."
        image={<Image fluid={query.middleDuoImage.childImageSharp.fluid} />}
      />
      <Duo
        title="Host your class on your preferred video service"
        body="Zoom, Uber Conference, Facetime, Google Hangouts - attach a link to share for your friends and family to join."
        reverse={true}
        image={<Image fluid={query.bottomDuoImage.childImageSharp.fluid} />}
      />
    </Layout>
  );
};

export default IndexPage;
